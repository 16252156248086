@charset "utf-8";
/* -------------------------------------------------------
	TOYOTA Biz Portal　ページ全体用 基本設定
------------------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
 margin: 0;
 padding: 0;
 border: 0;
 font-size: 100%;
 font: inherit;
 vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
 display: block;
}
/* 基本フォント設定 */
html{
 font-size: 62.5%;
}
body {
 font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue","Hiragino Sans","Hiragino Kaku Gothic ProN","Meiryo",sans-serif;
 font-weight: 400;
 font-size: 1.5rem;
 background-color:#fff;
 color: #0e3447;
 line-height: 1.9;
}
ol, ul, li {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
table {
 border-collapse: collapse;
 border-spacing: 0;
}
img{
 vertical-align: bottom;
}
*, *:before, *:after {
 -moz-box-sizing: border-box;
 -webkit-box-sizing: border-box;
 box-sizing: border-box;
}



/* -------------------------------------------------------
	よくあるご質問用
------------------------------------------------------- */
/* wrap */
.wrap{
 min-width: 1150px;
 position: relative;
}
.main-wrap{
 width:1000px;
 margin: 90px auto 100px;
}
/* ページタイトル */
.page-title{
 width:100%;
 height:120px;
 padding:0 50px;
 background-color: #f5f7f8;
 display: flex;
 align-items: center;
 justify-content: center;
}
.title-h1{
 width:1100px;
 font-size:2.6rem;
 line-height: 1.4;
}
/* 見出しテキストH3 */
.title-h3{
 margin-top:45px;
 padding:0 0 3px 22px;
 font-size:2rem;
 font-weight: 600;
 line-height: 1.4;
 letter-spacing: 1px;
 border-left: 2px solid #1b8ac2;
}
/* 見出しテキストH4 */
.title-h4 {
 margin-top: 25px;
 padding: 0 0 0 24px;
 font-size: 1.8rem;
 font-weight: 500;
 line-height: 1.4;
 letter-spacing: 1px;
 position: relative;
}
.title-h4::before {
 position: absolute;
 top: 50%;
 left: 0;
 content: '';
 width: 10px;
 height: 2px;
 background-color: #1b8ac2;
}
/* テキストリンク */
a.text-link,
a.text-link:visited{
 color:#1b8ac2;
 text-decoration: none;
}
a.text-link:hover,
a.text-link:active{
 text-decoration: underline;
}


/* Toyota Biz Centerとは
--------------------------------- */
.about-wrap{
 padding:25px 0 20px;
}
ul.function-list {
 display: flex;
 flex-wrap: wrap;
 align-items: center;
 justify-content: space-between;
 margin: 20px 0 15px;
}
ul.function-list li {
 display: flex;
 align-items: center;
 justify-content: center;
 width: 318px;
 height: 124px;
 font-size: 1.8rem;
 line-height: 1.4;
 font-weight: 600;
 text-align: center;
 color: #1b8ac2;
 background-color: #f1f8fb;
 border: 1px solid #bbdced;
}
ul.function-list li:nth-child(n+4) {
 margin-top: 25px;
}
ul.function-list li span {
 font-size: 1.4rem;
 font-weight: normal;
 color: #758891;
}

/* サービスのご利用について
--------------------------------- */
/* ステップ */
.howto-step {
 display: flex;
 width: 100%;
 margin:30px 0 40px;
 list-style: none;
}
.step-li {
 box-sizing: border-box;
 position: relative;
 width: calc((100% - 30px) / 3);
 height: 100px;
 margin: 0 3px;
 padding: 1px;
 background: #bbdced;
}
.step-li:nth-child(1) {
 z-index: 3
}
.step-li:nth-child(2) {
 z-index: 2
}
.step-li:nth-child(3) {
 z-index: 1
}
.step-li:first-child {
 margin-left: 0;
}
.step-li:last-child {
 margin-right: 0;
}
.step-li::before {
 position: absolute;
 content: "";
 top: 0;
 left: 1px;
 width: 0;
 height: 0;
 border-top: 50px solid transparent;
 border-right: 0;
 border-bottom: 50px solid transparent;
 border-left: 20px solid #bbdced;
}
.step-li::after {
 position: absolute;
 content: "";
 top: 0;
 left: 0;
 width: 0;
 height: 0;
 border-top: 50px solid transparent;
 border-right: 0;
 border-bottom: 50px solid transparent;
 border-left: 20px solid #fff;
}
.step-li_inner {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
 font-size: 21px;
 font-weight: bold;
 text-align: center;
 line-height: 1.4;
 background: #f1f8fb;
}
.step-li_inner .step_num{
 display: block;
 font-size: 16px;
 color:#1b8ac2;
 letter-spacing: 2px;
}
.step-li .step-li_inner::before {
 position: absolute;
 content: "";
 top: 0;
 left: 100%;
 width: 0;
 height: 0;
 border-top: 50px solid transparent;
 border-right: 0;
 border-bottom: 50px solid transparent;
 border-left: 20px solid #bbdced;
}
.step-li .step-li_inner::after {
 position: absolute;
 content: "";
 top: 0;
 left: calc(100% - 1px);
 width: 0;
 height: 0;
 border-top: 50px solid transparent;
 border-right: 0;
 border-bottom: 50px solid transparent;
 border-left: 20px solid #f1f8fb;
}
/* ステップ用タイトル */
.howto-step_ttl{
 padding-left:140px;
 margin-top: 18px;
 font-size: 21px;
 font-weight: bold;
 position: relative;
}
.howto-step_ttl .step_icon{
 position: absolute;
 left:0;
}
.step_icon {
 display: flex;
 justify-content: center;
 align-items: center;
 position: relative;
 padding-left: 10px;
 width: 110px;
 height: 40px;
 font-size: 16px;
 color:#fff;
 letter-spacing: 2px;
 background: #1b8ac2;
}
.step_icon::before {
 position: absolute;
 content: "";
 top: 0;
 left: 0;
 width: 0;
 height: 0;
 border-top: 20px solid transparent;
 border-right: 0;
 border-bottom: 20px solid transparent;
 border-left: 10px solid #fff;
}
.step_icon::after {
 position: absolute;
 content: "";
 top: 0;
 left: 100%;
 width: 0;
 height: 0;
 border-top: 20px solid transparent;
 border-right: 0;
 border-bottom: 20px solid transparent;
 border-left: 10px solid #1b8ac2;
}
/* ステップコンテンツ */
.howto-step_box{
 margin-top:20px;
 padding:0 0 30px 140px;
}
.dotline {
 background-image:url(
  data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAQAgMAAABM2DZgAAAADFBMVEX///8bisKp0+hxttm0ToQAAAAAE0lEQVQI12PQYLjOEArEGgxYAQA+4QJUnlXG1QAAAABJRU5ErkJggg==
  );
  background-position: left 50px top;
  background-repeat: repeat-y;
}

/* 企業登録／ID連携について
--------------------------------- */
.linked-text{
 margin-top:25px;
}

/* 手順 */
.howto-proc{
 margin-top:40px;
 background-color: #f5f7f8;
}
.proc-ttl1{
 padding:25px 40px;
 font-size: 20px;
 font-weight: bold;
 color: #1b8ac2;
}
.proc-wrap{
 padding:0 40px 40px;
}
/* フロー */
.proc-flow li {
 padding: 20px 25px;
 font-size: 1.6rem;
 background-color: #fff;
 border: 1px solid #bbdced;
 position: relative;
}
.proc-flow li+li {
 margin-top: 46px;
}
.proc-flow li+li::before {
 position: absolute;
 top: -30px;
 left: 50%;
 content: '';
 width: 0;
 height: 0;
 border-right: 15px solid transparent;
 border-left: 15px solid transparent;
 border-top: 12px solid #1b8ac2;
 margin-left: -8px;
}
ul.notes {
 padding-top: 15px;
}
ul.notes li {
 margin-top: 5px;
 line-height: 1.6;
 text-indent: -1em;
 padding-left: 1em;
}

