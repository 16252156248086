@charset "utf-8";
/* -------------------------------------------------------
	TOYOTA Biz Portal　ページ全体用 基本設定
------------------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
 margin: 0;
 padding: 0;
 border: 0;
 font-size: 100%;
 font: inherit;
 vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
 display: block;
}
/* 基本フォント設定 */
html{
 font-size: 62.5%;
}
body {
 font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue","Hiragino Sans","Hiragino Kaku Gothic ProN","Meiryo",sans-serif;
 font-weight: 400;
 font-size: 1.5rem;
 background-color:#fff;
 color: #0e3447;
 line-height: 1.9;
}
ol, ul, li {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
table {
 border-collapse: collapse;
 border-spacing: 0;
}
img{
 vertical-align: bottom;
}
*, *:before, *:after {
 -moz-box-sizing: border-box;
 -webkit-box-sizing: border-box;
 box-sizing: border-box;
}



/* -------------------------------------------------------
	サイト利用について用
------------------------------------------------------- */
/* wrap */
.wrap{
 min-width: 1150px;
 position: relative;
}
.main-wrap{
 width:1000px;
 margin: 80px auto 100px;
}
/* ページタイトル */
.page-title{
 width:100%;
 height:120px;
 padding:0 50px;
 background-color: #f5f7f8;
 display: flex;
 align-items: center;
 justify-content: center;
}
.title-h1{
 width:1100px;
 font-size:2.6rem;
 line-height: 1.4;
}
/* 見出しテキストH3 */
.title-h3{
 margin-top:40px;
 padding:0 0 3px 22px;
 font-size:2rem;
 font-weight: 600;
 line-height: 1.4;
 letter-spacing: 1px;
 border-left: 2px solid #1b8ac2;
}
/* 見出しテキストH4 */
.title-h4{
 margin-top:25px;
 padding:0 0 0 24px;
 font-size:1.8rem;
 font-weight: 500;
 line-height: 1.4;
 letter-spacing: 1px;
 position: relative;
}
.title-h4::before{
 position: absolute;
 top:50%;
 left:0;
 content: '';
 width:10px;
 height:2px;
 background-color: #1b8ac2;
}
/* 本文テキスト */
.title-h3 + .main-text{
 margin-top:25px;
}
.title-h4 + .main-text{
 margin-top:10px;
}
/* テキストリンク */
a.text-link,
a.text-link:visited{
 color:#1b8ac2;
 text-decoration: none;
}
a.text-link:hover,
a.text-link:active{
 text-decoration: underline;
}
/* パターン01ボタン */
.btn-base{
 height:88px;
 width: 410px;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 padding:0 20px 0 0;
 cursor: pointer;
 font-size: 1.8rem;
 line-height: 1.4;
 font-weight: 500;
 letter-spacing: 1px;
 color: #fff;
 text-align: center;
 vertical-align: middle;
 text-decoration: none;
 border:0;
 border-radius: 6px;
 background-color: #1b8ac2;
 transition: all 0.3s;
}
.btn-base::before{
 position: absolute;
 top:50%;
 right:35px;
 content: '';
 width:35px;
 height:1px;
 background-color: #fff;
 transition: all 0.3s;
}
.btn-base::after{
 position: absolute;
 top:50%;
 right:20px;
 transform: translateY(-6px);
 content: '';
 width: 0;
 height: 0;
 border-style: solid;
 border-width: 0 15px 7px 0px;
 border-color: transparent transparent #fff transparent;
 transition: all 0.3s;
}
.pattern1:hover{
 background-color: #74c8f2;
}




/* 
--------------------------------- */
/*リスト（アルファベット）*/
ul.list-link {
 margin:20px 0 20px 25px;
}
ul.list-link li {
 list-style: lower-alpha;
}
/*タイトル*/
.title-h3_sub{
 margin-top:35px;
 font-size:1.8rem;
 font-weight: 600;
}
.title-h3 + .title-h3_sub{
 margin-top:20px;
}
.title-h3_sub + .title-h4{
 margin-top:10px;
}
.title-h3_sub + .main-text{
 margin-top:10px;
}
/*テーブル*/
dl.terms-tbl{
 margin-top:15px;
 display:flex;
 flex-wrap: wrap;
 border-top: 1px solid #cfd6da;
}
dl.terms-tbl dt{
 width: 40%;
 padding: 20px;
 border-bottom: 1px solid #cfd6da;
}
dl.terms-tbl dd{
 width: 60%;
 padding: 20px;
 border-left: 1px solid #cfd6da;
 border-bottom: 1px solid #cfd6da;
}
ul.notes{
 padding-top:5px;
}
ul.notes li{
 margin-top:10px;
 font-size:1.3rem;
 line-height: 1.6;
 text-indent: -1em;
 padding-left: 1em;
}
.back-btn{
 display: flex;
 justify-content: center;
 margin-top:75px;
}


